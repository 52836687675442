<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3> {{module_name}}</h3>  
                    </template>
				</Toolbar> 
                
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button  v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" />  
					</template> 
				</Toolbar> 
				<DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" ></i>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                        :exportable="col.field == 'action' ? false : true" :sortable="col.field == 'action' ? false : true" 
                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction' : ''" 
                        :style="col.field !== 'action' ? 
                        'min-width:20rem;flex-grow:1; flex-basis:160px;'
                        :
                        'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 

                        <template  #body="{data}"> 
                            <span v-if="col.field == 'name'" @click="selectedRecordId=data.id; getClickedRecord('view');">
                                <b>{{data.name}} </b>
                            </span> 
                            <span v-if="col.field == 'status'"> 
                                <Tag :class="data.status == 'Active' ? 'mr-2 status-Active' : 'mr-2 status-Blocked'" icon="pi pi-check" :severity="data.status == 'Active' ? 'success' : 'danger'" :value="data.status"></Tag>
                            </span>
                            <span v-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span>   
                           
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Toast />
        
        <RoleForm v-on:formClose="closeForm" v-if="showForm" :recordId="selectedRecordId" :showForm="true" /> 
    </div>
</template>
<script>
import RoleForm from '../../components/AccessManagement/RoleForm.vue';
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                module_name:'Roles', 
                actions: [], 
                showHelp : false, 
                isSaving:false, 
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                showView:false,
                selectedRecordId: 0,  
                saveAndClose:false, 
			}
		},  
        components:{
            RoleForm,
        },
		created() { 
            this.actions.push({
                    label: 'View',
                    icon: 'pi pi-eye',
                    command: () => {
                        this.getClickedRecord('view');
                    }
                });
            this.actions.push({
                    label: 'Room No',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.getClickedRecord('roomNo');
                    }
                });
                
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'name', header: 'Name'},       
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 
			this.getRecords();  
		},
		methods: {   
            getClickedRecord(action){
                if(action == 'view'){
                    this.showView = true;
                }  
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true;
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.showForm = false;
                this.showView = false;
            }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllHostelRoles')
                .then( (response) => {  
                    this.records = response.data;  
                    this.loading = false; 
                })
                .catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message,'error');  
                }).finally(() => { 
                })  
            }, 
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
            },
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			},
            
		},
        watch : { 
        }
}
</script>
 
